import { Box, Grid, Flex, Text, Link, VStack, Skeleton, HTMLChakraProps } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import type { CustomLinksGroup } from 'types/footerLinks';

import config from 'configs/app';
import discussionsIcon from 'icons/discussions.svg';
import donateIcon from 'icons/donate.svg';
import editIcon from 'icons/edit.svg';
import cannyIcon from 'icons/social/canny.svg';
import discordIcon from 'icons/social/discord.svg';
import gitIcon from 'icons/social/git.svg';
import telegramIcon from 'icons/telegram.svg';
import twitterIcon from 'icons/twitter.svg';
import type { ResourceError } from 'lib/api/resources';
import useApiQuery from 'lib/api/useApiQuery';
import useFetch from 'lib/hooks/useFetch';
// import useIssueUrl from 'lib/hooks/useIssueUrl';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';

import ColorModeToggler from '../header/ColorModeToggler';
import FooterLinkIcon from './FooterLinkIcon';
import FooterLinkItem from './FooterLinkItem';
import IntTxsIndexingStatus from './IntTxsIndexingStatus';
import getApiVersionUrl from './utils/getApiVersionUrl';
import PopupCookie from './PopupCookie';

const MAX_LINKS_COLUMNS = 4;

const FRONT_VERSION_URL = `https://github.com/blockscout/frontend/tree/${ config.UI.footer.frontendVersion }`;
const FRONT_COMMIT_URL = `https://github.com/blockscout/frontend/commit/${ config.UI.footer.frontendCommit }`;

const Footer = () => {

  const { data: backendVersionData } = useApiQuery('config_backend_version', {
    queryOptions: {
      staleTime: Infinity,
    },
  });
  const apiVersionUrl = getApiVersionUrl(backendVersionData?.backend_version);
  // const issueUrl = useIssueUrl(backendVersionData?.backend_version);
  const BLOCKSCOUT_LINKS = [
    {
      icon: discussionsIcon,
      iconSize: '16px',
      text: 'Privacy Policy',
      url: 'https://tos.blastchain.org',
    },
    {
      icon: editIcon,
      iconSize: '16px',
      text: 'Submit an issue',
      url: 'mailto:support@blastchain.org',
    },
    {
      icon: cannyIcon,
      iconSize: '20px',
      text: 'Feature request',
      url: 'mailto:support@blastchain.org',
    },
    {
      icon: discussionsIcon,
      iconSize: '20px',
      text: 'Terms of Use',
      url: 'https://tos.blastchain.org',
    },
    {
      icon: gitIcon,
      iconSize: '18px',
      text: 'Github',
      url: 'https://github.com/blastchain',
    },
    // {
    //   icon: twitterIcon,
    //   iconSize: '18px',
    //   text: 'Twitter',
    //   url: 'https://www.twitter.com/blockscoutcom',
    // },
    // {
    //   icon: discordIcon,
    //   iconSize: '24px',
    //   text: 'Discord',
    //   url: '',
    // },
    // {
    //   icon: discussionsIcon,
    //   iconSize: '20px',
    //   text: 'Discussions',
    //   url: '',
    // },
    {
      icon: donateIcon,
      iconSize: '20px',
      text: 'Bridge',
      url: 'https://bridge.blastchain.org',
    },
  ];
  const SOCIAL_LINKS = [
    {
      icon: twitterIcon,
      iconSize: '22px',
      text: 'Twitter',
      url: 'https://x.com/blastblockchain',
    },
    {
      icon: telegramIcon,
      iconSize: '22px',
      text: 'Linkedin',
      url: 'https://t.me/Safeblastofficial/9439',
    },
  ];

  const frontendLink = (() => {
    if (config.UI.footer.frontendVersion) {
      return <Link href={ FRONT_VERSION_URL } target="_blank">{ config.UI.footer.frontendVersion }</Link>;
    }

    if (config.UI.footer.frontendCommit) {
      return <Link href={ FRONT_COMMIT_URL } target="_blank">{ config.UI.footer.frontendCommit }</Link>;
    }

    return null;
  })();

  const fetch = useFetch();

  const { isPending, data: linksData } = useQuery<unknown, ResourceError<unknown>, Array<CustomLinksGroup>>({
    queryKey: [ 'footer-links' ],
    queryFn: async() => fetch(config.UI.footer.links || '', undefined, { resource: 'footer-links' }),
    enabled: Boolean(config.UI.footer.links),
    staleTime: Infinity,
  });

  const colNum = Math.min(linksData?.length || Infinity, MAX_LINKS_COLUMNS) + 1;
  const containerProps: HTMLChakraProps<'div'> = {
    as: 'footer',
    borderTopWidth: '1px',
    borderTopColor: 'solid',
  };
  return (
    <Box { ...containerProps }>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        px={{ base: 4, lg: 12 }}
        py={{ base: 4, lg: 9 }}
        borderTop="1px solid"
        borderColor="divider"
        as="footer"
        columnGap={{ lg: '32px', xl: '100px' }}
      >
        <Box flexGrow="1" mb={{ base: 8, lg: 0 }} minW="195px">
          <Flex flexWrap="wrap" columnGap={ 8 } rowGap={ 6 }>
            <ColorModeToggler/>
            { !config.UI.indexingAlert.intTxs.isHidden && <IntTxsIndexingStatus/> }
            <NetworkAddToWallet/>
          </Flex>
          { /* <Box mt={{ base: 5, lg: '44px' }}>
            <Link fontSize="xs" href="https://www.blockscout.com">blockscout.com</Link>
          </Box> */ }
          <Text mt={ 3 } maxW={{ base: 'unset', lg: '470px' }} fontSize="xs">
            BLAST scan is a Block Explorer and Analytics platform for BLAST Chain, which is a decentralized smart contract platform..
          </Text>
          <Box mt={{ base: 5, lg: '10px' }} display="flex">
            { SOCIAL_LINKS.map(link => <FooterLinkIcon { ...link } key={ link.text }/>) }
          </Box>
          { /* <Text mt={ 50 } maxW={{ base: 'unset', lg: '470px' }} fontSize="xs">
          Donate: 0x10d8db9be1a1bcb29711050e4daeb9fa9288e86a
          </Text> */ }
          <VStack spacing={ 1 } mt={ 6 } alignItems="start">
            { apiVersionUrl && (
              <Text fontSize="xs">
                Backend: <Link href={ apiVersionUrl } target="_blank">{ backendVersionData?.backend_version }</Link>
              </Text>
            ) }
            { frontendLink && (
              <Text fontSize="xs">
                Frontend: { frontendLink }
              </Text>
            ) }
          </VStack>
        </Box>
        <Grid
          gap={{ base: 6, lg: config.UI.footer.links && colNum === MAX_LINKS_COLUMNS + 1 ? 2 : 8, xl: 12 }}
          gridTemplateColumns={ config.UI.footer.links ?
            {
              base: 'repeat(auto-fill, 160px)',
              lg: `repeat(${ colNum }, 135px)`,
              xl: `repeat(${ colNum }, 160px)`,
            } :
            'auto'
          }
        >
          <Box>
            { config.UI.footer.links && <Text fontWeight={ 500 } mb={ 3 }>Blockscout</Text> }
            <Grid
              gap={ 1 }
              gridTemplateColumns={
                config.UI.footer.links ?
                  '1fr' :
                  {
                    base: 'repeat(auto-fill, 160px)',
                    lg: 'repeat(3, 160px)',
                    xl: 'repeat(4, 160px)',
                  }
              }
              gridTemplateRows={{
                base: 'auto',
                lg: config.UI.footer.links ? 'auto' : 'repeat(3, auto)',
                xl: config.UI.footer.links ? 'auto' : 'repeat(3, auto)',
              }}
              gridAutoFlow={{ base: 'row', lg: config.UI.footer.links ? 'row' : 'column' }}
              mt={{ base: 0, lg: config.UI.footer.links ? 0 : '100px' }}
            >
              { BLOCKSCOUT_LINKS.map(link => <FooterLinkItem { ...link } key={ link.text }/>) }
            </Grid>
          </Box>
          { config.UI.footer.links && isPending && (
            Array.from(Array(3)).map((i, index) => (
              <Box key={ index }>
                <Skeleton w="100%" h="20px" mb={ 6 }/>
                <VStack spacing={ 5 } alignItems="start" mb={ 2 }>
                  { Array.from(Array(5)).map((i, index) => <Skeleton w="100%" h="14px" key={ index }/>) }
                </VStack>
              </Box>
            ))
          ) }
          { config.UI.footer.links && linksData && (
            linksData.slice(0, MAX_LINKS_COLUMNS).map(linkGroup => (
              <Box key={ linkGroup.title }>
                <Text fontWeight={ 500 } mb={ 3 }>{ linkGroup.title }</Text>
                <VStack spacing={ 1 } alignItems="start">
                  { linkGroup.links.map(link => <FooterLinkItem { ...link } key={ link.text }/>) }
                </VStack>
              </Box>
            ))
          ) }
        </Grid>
      </Flex>

      <PopupCookie/>
    </Box>
  );
};

export default Footer;
